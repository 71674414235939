<template>
  <v-app style="width: 100%;">
    <v-card style="margin: 20px;">
      <vx-select
        v-model="classy"
        v-on:search="getStudents($event)"
        :options="StudentsInClass"
        style="width: 300px;margin: 10px;"
      ></vx-select>
    </v-card>
  </v-app>
</template>

<script>
  export default {
    name: 'Communication',
  }
</script>

<style scoped>

</style>
